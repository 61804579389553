import React, { useRef, useEffect, useState } from "react";
import { useMessages } from "../contexts/MessageProvider";
import { useAuth } from "../contexts/AuthProvider";
import ChatApi from "../services/chatAPIService";

import Message from "./Message";
import InputBox from "./InputBox";
import SuggestionButton from "./SuggestionButton";
import DataTable from "./DataTable";
import TypingIndicator from "./animation/TypingIndicator"

import "./ChatWindow.css";
import "./Message.css";
import logo from "../images/logo.svg";

function ChatWindow({ chatId }) {
  const RESUMO_PACIENTE_CONTEXT = ["resumo_paciente", "Resumo do Paciente", "Você selecionou **Resumo do Paciente**. Envie o ID do paciente para darmos início à conversa."];
  const GESTAO_LEITOS_CONTEXT = ["gestao_leitos", "Gestão de Leitos", "Você selecionou **Análise de Leitos**. Envie as informações do paciente para darmos início à conversa."];
  const TAXA_OCUPACAO_CONTEXT = ["taxa_ocupacao_leitos", "Taxa de Ocupação de Leitos", "Você selecionou **Taxa de Ocupação de Leitos**."];
  const DUVIDAS_GERAIS = ["duvidas_gerais", "Dúvidas Gerais", "Você selecionou **Dúvidas Gerais**. Envie seu questionamento para darmos início à conversa."];

  const chatWindowRef = useRef(null);

  const { messages, updateMessages, addMessage } = useMessages();
  const { sendPrompt, getChatHistory, getTitle, getContextValue, getDataTable, hasError, isPatientNotFound } = ChatApi(useAuth().userToken);

  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [chatInit, setChatInit] = useState(false);
  const [selectedContextValue, setSelectedContextValue] = useState("");
  const [newChatId, setNewChatId] = useState(chatId || "");
  const [hideInput, setHideInput] = useState(false);
  const [chatInfo, setChatInfo] = useState(null);

  const handleChatSubmit = async (message, contextValue) => {
    if (loadingAnswer) return;

    setLoadingAnswer(true);

    contextValue = selectedContextValue || contextValue;
    let prompt;

    if (contextValue === TAXA_OCUPACAO_CONTEXT[0]) {
      prompt = message;
    } else {
      prompt = `${contextValue}#${message}`;
      addMessage(message, "user");
    }

    try {
      const chatData = await sendPrompt(prompt, newChatId);
      updateMessages(chatData.chat.messages);
      setNewChatId(chatData.chat.id);
      setChatInfo(chatData.chat)

    } catch (error) {
      console.error("Error while fetching chat response:", error);

    } finally {
      setLoadingAnswer(false);
    }
  };

  const initSuggestionChat = async (contextValue) => {
    setSelectedContextValue(contextValue);
    setLoadingAnswer(false);
    setChatInit(true)

    if (contextValue === TAXA_OCUPACAO_CONTEXT[0]) {
      await handleChatSubmit(
        `${TAXA_OCUPACAO_CONTEXT[0]}#`,
        contextValue
      );
    }
  };

  const getContextMessage = () => {
    if(selectedContextValue === RESUMO_PACIENTE_CONTEXT[0]){
      return RESUMO_PACIENTE_CONTEXT[2];

    } else if(selectedContextValue === GESTAO_LEITOS_CONTEXT[0]) {
      return GESTAO_LEITOS_CONTEXT[2];

    } else if(selectedContextValue === TAXA_OCUPACAO_CONTEXT[0]) {
      return TAXA_OCUPACAO_CONTEXT[2];

    } else {
      return DUVIDAS_GERAIS[2];
    }
  }

  const getPatientDashboardMessage = () => {
    console.log(messages)
    const patientId = messages[messages.length - 2].content;
    const dashboardPath = "/dashboard/" + patientId;
    return `Clique no link abaixo para acessar o dashboard do paciente: \n [Dashboard do Paciente](${dashboardPath})`;
  };

  const resumoPacienteChatFinished = () => {
    return (
      !loadingAnswer &&
      selectedContextValue === RESUMO_PACIENTE_CONTEXT[0] &&
      messages.length > 1 &&
      !hasError(chatInfo) && 
      !isPatientNotFound(chatInfo)
    );
  };

  const showTableContent = () => {
    return (
      !loadingAnswer &&
      selectedContextValue === TAXA_OCUPACAO_CONTEXT[0] &&
      !hasError(newChatId)
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const canHideInput = () => {
    if (selectedContextValue === TAXA_OCUPACAO_CONTEXT[0]) {
      return true;
    } else if (selectedContextValue === RESUMO_PACIENTE_CONTEXT[0]) {
      return resumoPacienteChatFinished();
    }
    return false;
  };

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSelectChatHistory = async (chatId) => {
    try {
      const history = await getChatHistory(chatId);
      return history;

    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  };

  useEffect(() => {
    setHideInput(canHideInput());
  }, [canHideInput]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (chatId !== undefined && messages.length === 0) {
        try {
          const chatInfo = await getSelectChatHistory(chatId);
          setChatInfo(chatInfo);
          const storedMessages = chatInfo.messages;
          const context = chatInfo.context.toLowerCase();
  
          if (storedMessages) {
            updateMessages(storedMessages, true);
            setSelectedContextValue(context);
          }
        } catch (error) {
          console.error("Error fetching stored messages:", error);
        } finally {
          setLoadingMessages(false);
        }
      } else {
        setLoadingMessages(false);
      }
    };
  
    fetchMessages();
  }, [chatId, messages.length, updateMessages, getTitle, getContextValue, getChatHistory, getSelectChatHistory]);
  

  if (loadingMessages) {
    return null;
  }

  if (messages.length === 0 && !chatInit) {
    return (
      <div className="chat-main-content">
        <div className="chat-window empty-chat">
          <div className="title-content mb-3">
            <img src={logo} alt="Assistant Logo" className="assistant-logo" />
            <h2 className="assistant-name">Assistente Virtual Hospitalar</h2>
            <h4 className="assistant-sub-name">
              Escolha o prompt inicial para dar início ao chat
            </h4>
          </div>
          <div className="suggestions">
            <SuggestionButton
              context={GESTAO_LEITOS_CONTEXT[1]}
              contextValue={GESTAO_LEITOS_CONTEXT[0]}
              message={GESTAO_LEITOS_CONTEXT[2]}
              initSuggestionChat={initSuggestionChat}
            />

            <SuggestionButton
              context={RESUMO_PACIENTE_CONTEXT[1]}
              contextValue={RESUMO_PACIENTE_CONTEXT[0]}
              message={RESUMO_PACIENTE_CONTEXT[2]}
              initSuggestionChat={initSuggestionChat}
            />

            <SuggestionButton
              context={TAXA_OCUPACAO_CONTEXT[1]}
              contextValue={TAXA_OCUPACAO_CONTEXT[0]}
              message={TAXA_OCUPACAO_CONTEXT[2]}
              initSuggestionChat={initSuggestionChat}
            />

            <SuggestionButton
              context={DUVIDAS_GERAIS[1]}
              contextValue={DUVIDAS_GERAIS[0]}
              message={DUVIDAS_GERAIS[2]}
              initSuggestionChat={initSuggestionChat}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="chat-main-content">
      <div className="chat-window" ref={chatWindowRef}>
        <Message content={getContextMessage()} role={"assistant"} />
       
        {messages.map((message, index) => 
          message.data == null ? (
            <Message key={index} content={message.content} role={message.role} />
          ) : null
        )}

        {resumoPacienteChatFinished() && (
          <Message content={getPatientDashboardMessage()} role={"assistant"} />
        )}
        
        {showTableContent() && <DataTable data={getDataTable(chatInfo)} />}
        {loadingAnswer && 
          <div className="chat-item assistant-chat-item">
            <div className="message assistant-message p-2">
              <TypingIndicator />
            </div>
          </div>}
      </div>
      
      {!hideInput && (
        <InputBox
          loadingAnswer={loadingAnswer}
          handleChatSubmit={handleChatSubmit}
        />
      )}
    </div>
  );
}

export default ChatWindow;
