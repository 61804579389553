import axios from 'axios';

const getDashboardData = async (token) => {
  const ENDPOINT = process.env.REACT_APP_API_DASHBOARD_ENDPOINT;

  try {
    const response = await axios.get(ENDPOINT, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.object;  
  } catch (error) {
    console.error('Error getting dashboard data:', error);
    throw error;

  }
};

const getPatientDashboardData = async (token, patientId) => {
  const ENDPOINT = process.env.REACT_APP_API_PATIENT_DASHBOARD_ENDPOINT + "/" + patientId;

  try {
    const response = await axios.get(ENDPOINT, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.object;  
  } catch (error) {
    console.error('Error getting dashboard data:', error);
    throw error;

  }
};

const getMostUsedMedicesGraphData = (dashboardData) => {
  if (!dashboardData || !dashboardData.graphMostUsedMedicine) return null; 

  const medicines = Object.keys(dashboardData.graphMostUsedMedicine.medicinesQuantityMap);
  const medicinesQuantities = Object.values(dashboardData.graphMostUsedMedicine.medicinesQuantityMap);
  const title =  dashboardData.graphMostUsedMedicine.title;

  const barChartOptions = {
    title: {
      text:title,
      left: 'center'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: medicines,
        name: 'Medicamentos',
        nameLocation: 'center',
        nameGap: 38,
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          interval: 0, 
          rotate: 28,   
          formatter: function(value) {
            if (value.length > 8) {
              return value.substring(0, 8) + '...';  
            }
            return value;  
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Quantidade',
        nameLocation: 'middle',
        nameGap: 50
      }
    ],
    series: [
      {
        name: 'Quantidade',
        type: 'bar',
        barWidth: '60%',
        data: medicinesQuantities ,
        itemStyle: {
          color: '#0C3760'
        }
      }
    ]
  };

  return barChartOptions;
};

const getMostCommonDiseasesGraphData = (dashboardData) => {
  if (!dashboardData || !dashboardData.graphMostCommonDiseases) return null;

  const diseases = Object.keys(dashboardData.graphMostCommonDiseases.diseasesQuantityMap);
  const diseasesQuantities = Object.values(dashboardData.graphMostCommonDiseases.diseasesQuantityMap);
  const title =  dashboardData.graphMostCommonDiseases.title;

  const barChartOptions = {
    title: {
      text: title, 
      left: 'center'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: diseases,
        name: 'Doenças',
        nameLocation: 'center',
        nameGap: 45,
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          interval: 0, 
          rotate: 28,   
          formatter: function(value) {
            if (value.length > 8) {
              return value.substring(0, 8) + '...';  
            }
            return value;  
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Ocorrência',
        nameLocation: 'middle',
        nameGap: 50
      }
    ],
    series: [
      {
        name: 'Ocorrência',
        type: 'bar',
        barWidth: '60%',
        data: diseasesQuantities,
        itemStyle: {
          color: '#1588C9'  
        }
      }
    ]
  };

  return barChartOptions;
};

const getMostCommonProceduresGraphData = (dashboardData) => {
  if (!dashboardData || !dashboardData.graphMostCommonProcedures) return null;

  const procedures = Object.keys(dashboardData.graphMostCommonProcedures.proceduresQuantityMap);
  const proceduresQuantities = Object.values(dashboardData.graphMostCommonProcedures.proceduresQuantityMap);
  const title =  dashboardData.graphMostCommonProcedures.title;

  const barChartOptions = {
    title: {
      text: title, 
      left: 'center'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: procedures,
        name: 'Procedimentos',
        nameLocation: 'center',
        nameGap: 43,
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          interval: 0, 
          rotate: 28,   
          formatter: function(value) {
            if (value.length > 8) {
              return value.substring(0, 8) + '...';  
            }
            return value;  
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Ocorrência',
        nameLocation: 'middle',
        nameGap: 50
      }
    ],
    series: [
      {
        name: 'Ocorrência',
        type: 'bar',
        barWidth: '60%',
        data: proceduresQuantities
      }
    ]
  };

  return barChartOptions;
};

const isDataEmpty = (dashboardData, graph, dataAtribute) => {
  if (dashboardData === null || dashboardData[graph] === undefined) return false;
  
  const attributeValue = dashboardData[graph][dataAtribute];
  if (attributeValue && typeof attributeValue === 'object' && Object.keys(attributeValue).length === 0) {
    return true;
  }

  return false;
};

export {
  getDashboardData,
  getMostUsedMedicesGraphData,
  getMostCommonDiseasesGraphData,
  getMostCommonProceduresGraphData,
  getPatientDashboardData,
  isDataEmpty
};
