import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

import './ChatHistory.css'; 

function ChatHistory({ chat }) {
    return (
        <div className="chat-history-container">
            <CircleIcon className={chat.chatStatus !== 'SUCCESS'? 'chat-history-status-error me-2' : 'chat-history-status-success me-2'} />
            <div className="chat-history-title">{chat.title}</div>
        </div>
    );
}

export default ChatHistory;
