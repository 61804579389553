import React, { createContext, useContext, useState } from 'react';

const MessagesContext = createContext();

export const MessagesProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  function updateMessages(messagesList) {
    setMessages(messagesList);
  }
  
  function addMessage(message, role) {
    updateMessages([...messages, { content: message, role: role }]);
  }  

  
  return (
    <MessagesContext.Provider value={{ messages, updateMessages, setMessages, addMessage }}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);
