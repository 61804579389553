import React from 'react';
import './SuggestionButton.css';

function SuggestionButton({ context, contextValue, initSuggestionChat, message }) {

  const handleSuggestionButtonSelect = () => {
    initSuggestionChat(contextValue, message);
  };

  return (
    <button onClick={handleSuggestionButtonSelect} className="suggestion-button">
      {context}
    </button>
  );
}

export default SuggestionButton;
