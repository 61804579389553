import React from 'react';
import './DataTable.css'; 

function DataTable({ data }) {
    if (data === undefined || data === null) return null;

    const jsonData = JSON.parse(data);
    const headers = Object.keys(jsonData[0]);

    const getLastColumnBackgroundColor = (value) => {
        const percentage = parseFloat(value); 
        if (percentage < 50) {
            return '#4EC91512'; 
        } else if (percentage >= 50 && percentage < 80) {
            return '#FFC70024'; 
        } else {
            return '#FFEBEB'; 
        }
    };

    return (
        <div className="data-table">
            <table>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {jsonData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {headers.map((header, cellIndex) => {
                                const cellValue = row[header];
                                const isLastColumn = cellIndex === headers.length - 1; 
                                const cellStyle = isLastColumn ? { backgroundColor: getLastColumnBackgroundColor(cellValue) } : {};

                                return (
                                    <td key={cellIndex} style={{ ...cellStyle, ...(rowIndex === jsonData.length - 1 ? { fontWeight: 'bold' } : {}) }}>
                                        {cellValue}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DataTable;
