import React from 'react';
import './ChatIcon.css'; 

function ChatIcon({ src, alt, user }) {
    return (
        <div className="user-icon">
            <img src={src} alt={alt} className={user ? "user-icon-image" : "chat-icon-image" }/>
        </div>
    );
}

export default ChatIcon;
