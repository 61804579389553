import React from 'react';
import Sidebar from '../Sidebar';
import Dashboard from '../dashboard/Dashboard';

function DashboardRoute() {
  return (
    <div className="app">
      <Sidebar />
      <div className="main-content">
        <Dashboard></Dashboard>
      </div>
    </div>
  );
}

export default DashboardRoute;
