import React from 'react';
import { TypeAnimation } from 'react-type-animation';

const TypingIndicator = () => {
  return (
    <TypeAnimation
      sequence={['.', 500, '..', 500, '...', 500]}
      speed={50} 
      repeat={Infinity}
      style={{ fontSize: '24px', color: '#999' }}
    />
  );
};

export default TypingIndicator;
