import axios from 'axios';
import { useCallback } from 'react';

const ChatApi = (token) => {

  const PROMPT_ENDPOINT = process.env.REACT_APP_API_GENERATE_ENDPOINT;
  const GET_CHATS_ENDPOINT = process.env.REACT_APP_API_GET_CHATS_ENDPOINT;
  const GET_CHAT_MSG_ENDPOINT = process.env.REACT_APP_API_GET_CHAT_MSG_ENDPOINT;

  const sendPrompt = useCallback(async (message, chatId) => {
    try {
      const response = await axios.post(PROMPT_ENDPOINT, {
        query: message,
        chatId: chatId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      return response.data.object;

    } catch (error) {
      console.error('Error in API call:', error);
      throw error;
    }
  }, [token, PROMPT_ENDPOINT]);

  const getChats = useCallback(async () => {
    try {
      const response = await axios.get(GET_CHATS_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      return response.data.object;
  
    } catch (error) {
      console.error('Error in API call:', error);
      throw error;
    }
  }, [token, GET_CHATS_ENDPOINT]);  

  const getChat = useCallback(async (chatId) => {
    try {
      const getMsgEndpoint = `${GET_CHAT_MSG_ENDPOINT}${chatId}`;
      const response = await axios.get(getMsgEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      return response.data.object;
  
    } catch (error) {
      console.error('Error in API call:', error);
      throw error;
    }
  }, [token, GET_CHAT_MSG_ENDPOINT]);

  const getChatHistory = async (chatId) => {
      const chat = await getChat(chatId);
      return chat;
  };

  const getTitle = (chatInfo) => {
      return chatInfo.title;
  };

  const getContextValue = (chatInfo) => {
      return chatInfo.context;
  };

  const getChatDate = (chatInfo) => {
      return chatInfo.date;
  };

  const getDataTable = (chatInfo) => {
      return chatInfo.messages[0].data;
  }

  const hasError = (chatInfo) => {
      return chatInfo.chat_status === "ERROR";
  }

  const isPatientNotFound = (chatInfo) => {
      return chatInfo.chatStatus === "PATIENT_NOT_FOUND";
  }

  return { sendPrompt, getChatHistory, getChatDate, getChats, getTitle, getContextValue, getDataTable, hasError, isPatientNotFound };
};

export default ChatApi;
