import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import BarChartComponent from "./BarChartComponent"; 
import {
  getPatientDashboardData,
  getMostUsedMedicesGraphData,
  getMostCommonDiseasesGraphData,
  getMostCommonProceduresGraphData,
  isDataEmpty
} from "../../services/dashboardService";

import "./PatientDashboard.css";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function PatientDashboard({ patientId }) {
  const { userToken } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const data = await getPatientDashboardData(userToken, patientId); 
        setDashboardData(data);
      } catch (error) {
        console.error('Erro ao carregar os dados do dashboard:', error);
      }
    };

    if (userToken) {
      fetchDashboardData();
    }
  }, [userToken, patientId]);

  if (!dashboardData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="dashboard-main-content">
      <div className="dashboard-charts">
      <h3 className="dashboard-title">Dashboard do Paciente {patientId}</h3>
        {isDataEmpty(dashboardData, "graphMostUsedMedicine","medicinesQuantityMap") ? 
          <div className="card empty-graph">Sem informações sobre os medicamentos mais utilizados pelo paciente.</div> :
          <div className="card graph-card">
              <BarChartComponent options={getMostUsedMedicesGraphData(dashboardData)} />
          </div>
        }

        {isDataEmpty(dashboardData, "graphMostCommonDiseases","diseasesQuantityMap") ? 
          <div  className="card empty-graph">Sem informações sobre as doenças mais recorrentes no paciente.</div> :
          <div className="card graph-card">
              <BarChartComponent options={getMostCommonDiseasesGraphData(dashboardData)} />
          </div>
        }

        {isDataEmpty(dashboardData, "graphMostCommonProcedures","proceduresQuantityMap") ? 
          <div  className="card empty-graph">Sem informações sobre os procedimentos mais realizados pelo paciente.</div> :
          <div className="card graph-card">
             <BarChartComponent options={getMostCommonProceduresGraphData(dashboardData)} />
          </div>
        }
      </div>
    </div>
  );
}

export default PatientDashboard;
