import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import BarChartComponent from "./BarChartComponent"; 
import { getDashboardData, getMostUsedMedicesGraphData, getMostCommonDiseasesGraphData, getMostCommonProceduresGraphData } from "../../services/dashboardService";

import "./Dashboard.css";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Dashboard() {
  const { userToken } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const data = await getDashboardData(userToken); 
        setDashboardData(data);
      } catch (error) {
        console.error('Erro ao carregar os dados do dashboard:', error);
      }
    };

    if (userToken) {
      fetchDashboardData();
    }
  }, [userToken]);

  if (!dashboardData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="dashboard-main-content">
      <div className="dashboard-charts">
      <h3 className="dashboard-title">Visão Geral do Hospital</h3>
        <div className="card graph-card">
          <BarChartComponent options={getMostUsedMedicesGraphData(dashboardData)} />
        </div>
        <div className="card graph-card">
          <BarChartComponent options={getMostCommonDiseasesGraphData(dashboardData)} />
        </div>
        <div className="card graph-card">
          <BarChartComponent options={getMostCommonProceduresGraphData(dashboardData)} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
