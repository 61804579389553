import React, { useState, useEffect } from "react";
import History from "./History";
import MenuIcon from "@mui/icons-material/Menu";
import ChatIcon from '@mui/icons-material/Chat';
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useAuth } from "../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";

import "./Sidebar.css";

function Sidebar() {
  const { logout, userRole } = useAuth();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(userRole === "USER");
  }, [userRole]);

  const toggleSidebar = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const renderAdminMenu = () => (
    <>
      <div>
        <div className="menu-icon" onClick={toggleSidebar}>
          <MenuIcon />
        </div>
        <div className="menu-icon" onClick={handleNavigation("/dashboard")}>
          <DashboardIcon />
        </div>
        {!isExpanded && (
          <a className="menu-icon" href="/chat/new">
            <ChatIcon />
          </a>
        )}
      </div>
      {isExpanded && (
        <div className="history-container mt-3">
          <History />
        </div>
      )}
      <div className="new-chat">
        {isExpanded ? (
          <a className="new-chat-btn" href="/chat/new">
            <button className="btn btn-primary">Novo chat</button>
          </a>
        ) : (
          <div className="menu-icon" onClick={logout}>
            <LogoutIcon />
          </div>
        )}
      </div>
    </>
  );

  const renderUserMenu = () => (
    <>
      <div>
        <div className="menu-icon" onClick={toggleSidebar}>
          <MenuIcon />
        </div>
        {!isExpanded && (
          <a className="menu-icon" href="/chat/new">
           <ChatIcon />
         </a>
        )}
      </div>
      {isExpanded && (
        <div className="history-container mt-3">
          <History />
        </div>
      )}
      <div className="new-chat">
        {isExpanded ? (
          <a className="new-chat-btn" href="/chat/new">
            <button className="btn btn-primary">Novo chat</button>
          </a>
        ) : (
          <div className="menu-icon" onClick={logout}>
            <LogoutIcon />
          </div>
        )}
      </div>
    </>
  );

  const renderManagerMenu = () => (
    <>
      <div>
        <div className="menu-icon" onClick={handleNavigation("/dashboard")}>
          <DashboardIcon />
        </div>
      </div>
      <div className="new-chat">
          <div className="menu-icon" onClick={logout}>
            <LogoutIcon />
          </div>
      </div>
    </>
  );

  return (
    <div className={`sidebar ${isExpanded ? "expanded" : ""}`}>
      {userRole === "ADMIN" && renderAdminMenu()}
      {userRole === "USER" && renderUserMenu()}
      {userRole === "MANAGER" && renderManagerMenu()}
    </div>
  );
}

export default Sidebar;
