import React from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../Sidebar';
import ChatWindow from '../ChatWindow';

function ChatRoute() {
  const { chatId } = useParams();

  return (
    <div className="app">
      <Sidebar />
      <div className="main-content">
        {chatId === "new" ? <ChatWindow key="new" /> : <ChatWindow key={chatId} chatId={chatId} />}
      </div>
    </div>
  );
}

export default ChatRoute;
