import React from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import { GridComponent, TooltipComponent, TitleComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

function BarChartComponent({ options }) {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={options}
      theme={"walden"} 
      style={{ height: '100%', width: '100%' }} 
    />
  );
}

export default BarChartComponent;
